<template>
  <div v-if="socials" class="my-6 p-2 bg-white rounded">
    <label class="later-form-label ml-1" v-text="label" />
    <div
      v-for="social in socials"
      :key="social.hash"
      class="flex items-center my-2"
    >
      <mdc-checkbox
        v-model="social.selected"
        :disabled="disabled"
        @input="clickBox"
      />
      <img :src="social.avatar" class="rounded-full w-12 h-12" />
      <b class="ml-4" v-text="`${social.name}`" />
      <span class="mx-1" v-text="'on'" />
      <b class="capitalize" v-text="social.type" />
    </div>
  </div>
</template>

<script>
import MdcCheckbox from '@/components/mdc/checkbox.vue';

export default {
  components: {
    MdcCheckbox,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    socials: null,
  }),

  computed: {
    selectedSocials() {
      return this.socials.filter((social) => social.selected);
    },
  },

  mounted() {
    this.socials = JSON.parse(
      JSON.stringify(this.disabled ? this.value : this.$store.getters.socials)
    );
    const hashes = this.value.map((social) => social.hash);
    this.socials.forEach((social) => {
      this.$set(social, 'selected', hashes.includes(social.hash));
    });
  },

  methods: {
    clickBox() {
      this.$emit('input', this.selectedSocials);
    },
  },
};
</script>

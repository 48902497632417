<template>
  <form v-if="body != null" class="sm:m-6 max-w-screen-md" @submit.prevent>
    <later-calendar
      v-model="sendAt"
      :disabled="isReadOnly"
      label="When to post"
      @input="dirty = true"
    />
    <later-socials
      v-model="socials"
      :disabled="isReadOnly"
      label="Where to post"
      @input="dirty = true"
    />
    <later-wysiwyg
      v-model="body"
      :disabled="isReadOnly"
      class="rounded"
      @input="dirty = true"
    />

    <div class="flex items-baseline">
      <mdc-button
        v-if="!isReadOnly"
        :disabled="status === 'scheduled' && !isDirty"
        class="mr-8"
        ripple
        raised
        @click.prevent.native="submitYo(true)"
      >
        Commit
      </mdc-button>
      <mdc-button
        v-if="!isReadOnly"
        :disabled="status === 'draft' && !isDirty"
        class="mr-8"
        ripple
        @click.prevent.native="submitYo(false)"
      >
        Save as Draft
      </mdc-button>
      <mdc-button class="mr-8" @click.native="$router.push('/post')">
        Go Back
      </mdc-button>
      <mdc-button
        danger
        @click.prevent.native="trash"
        v-text="status === 'trash' ? 'Delete Forever' : 'Trash'"
      />
      <div class="flex-1" />
      <later-status :status="status" />
      <strong v-show="isDirty" v-text="' *'" />
    </div>
  </form>
</template>

<script>
import LaterCalendar from '@/components/calendar.vue';
import LaterSocials from '@/components/socials.vue';
import LaterStatus from '@/components/status.vue';
import LaterWysiwyg from '@/components/wysiwyg.vue';
import MdcButton from '@/components/mdc/button.vue';
import axios from '@/utils/xhr';
import { alertDialog } from '@/utils/dialog';
import { dateFromServer, dateToServer } from '@/utils/date';

export default {
  components: {
    LaterCalendar,
    LaterSocials,
    LaterStatus,
    LaterWysiwyg,
    MdcButton,
  },

  data: () => ({
    sendAt: null,
    socials: [],
    body: null,
    status: '',
    dirty: false,
  }),

  computed: {
    isDirty() {
      return !this.isReadOnly && this.dirty;
    },
    isReadOnly() {
      return (
        !this.status ||
        this.status === 'trash' ||
        this.status === 'queued' ||
        this.status === 'sent'
      );
    },
  },

  mounted() {
    axios
      .get(
        `/post/${this.$route.params.hash}${
          this.$route.params.hash !== 'create' ? '/edit' : ''
        }`
      )
      .then((response) => {
        if (this.$route.params.hash === 'create')
          this.$router.replace(`/post/${response.data.hash}`);
        this.sendAt = dateFromServer(response.data.send_at);
        this.socials = response.data.socials;
        this.status = response.data.deleted_at ? 'trash' : response.data.status;
        this.body = response.data.body;
      });

    window.addEventListener('beforeunload', this.handleUnload);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleUnload);
  },

  beforeRouteLeave(to, from, next) {
    if (this.isDirty)
      this.submitYo(this.status === 'scheduled')
        .then(() => next())
        .catch(() => next(false));
    else next();
  },

  methods: {
    handleUnload(e) {
      if (!this.isDirty) return undefined;
      const msg = 'You have unsaved changes!';
      (e || window.event).returnValue = msg; // Gecko + IE
      return msg; // Gecko + Webkit, Safari, Chrome etc.
    },

    submitYo(validate) {
      const errors = [];
      if (this.validate) {
        if (!this.sendAt.isValid)
          errors.push('Please specify a date to post this message.');
        if (this.socials.length < 1)
          errors.push('Please specify where to post this message to.');
        if (this.body.length < 1 || this.body === '<p></p>')
          errors.push('Please write a little something.');
      }

      return new Promise((resolve, reject) => {
        if (errors.length > 0) {
          alertDialog('Whoops!', errors.join('<br>'));
          reject();
          return;
        }
        axios
          .post(`/post/${this.$route.params.hash}`, {
            validate,
            send_at: dateToServer(this.sendAt),
            socials: this.socials.map((social) => social.hash),
            body: this.body,
          })
          .then(() => {
            this.dirty = false;
            if (validate) {
              this.$store.commit(
                'snackbarMessage',
                'Your post has been scheduled!'
              );
              this.$router.push({ name: 'posts' });
            } else {
              this.$store.commit(
                'snackbarMessage',
                'Post has been saved as a draft.'
              );
              this.status = 'draft';
            }
            resolve();
          })
          .catch(() => reject());
      });
    },

    trash() {
      this.dirty = false;
      axios
        .post('/post/trash', {
          action: this.status === 'trash' ? 'delete' : 'trash',
          posts: [this.$route.params.hash],
        })
        .then(() => {
          this.$store.commit(
            'snackbarMessage',
            `Your post has been ${
              this.status === 'trash' ? 'deleted' : 'moved to trash'
            }.`
          );
          this.$router.push({ name: 'posts' });
        });
    },
  },
};
</script>
